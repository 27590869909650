'use client'

import React, { useCallback, useState, useEffect } from 'react'
import Link from 'next/link'
import styles from './LoginForm.module.css'
import GoogleSignIn from './GoogleSignIn'
import useLogin, { LoginErrors } from '@crystal-eyes/hooks/useLogin'
import Button from '@crystal-eyes/clear-components/Buttons/Button'
import { match } from 'ts-pattern'
import { debounce } from 'throttle-debounce'
import { getSSODetails } from '@crystal-eyes/utils/apis/authApi'
import { authApiUrl } from '@crystal-eyes/config'
import classNamesBind from 'classnames/bind'
import TextInput from '@crystal-eyes/clear-components/Inputs/TextInput'

const cn = classNamesBind.bind(styles)

export default function LoginForm() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [twoFactorCode, setTwoFactorCode] = useState('')
  const { success, loading, error, doLogin } = useLogin(tokens => {
    window.location.href = `/login/status?login_success=true&login_refresh=${tokens.refresh}`
  })

  const checkEmailSSO = useCallback(
    debounce(500, async email => {
      const myHost = `${window.location.protocol}//${window.location.host}`

      if (email) {
        getSSODetails({
          email,
          redirectUri: `${myHost}/login/status`,
        }).then(resp => {
          if (resp?.url) {
            window.location.href = resp.url
          }
        })
      }
    }),
    [],
  )

  useEffect(() => {
    checkEmailSSO(email)
  }, [email])

  const onGoogleSignIn = useCallback(
    (_googleToken: string, email: string) => {
      const myHost = `${window.location.protocol}//${window.location.host}`
      getSSODetails({
        email,
        redirectUri: `${myHost}/login/status`,
      }).then(() => {
        window.location.href = `${authApiUrl}/login/google`
      })
    },
    [doLogin],
  )
  const onSubmit = useCallback(
    (evt: React.FormEvent) => {
      evt.preventDefault()
      doLogin(email, password, { twoFactorCode })
    },
    [email, password, twoFactorCode, doLogin],
  )

  const state =
    error !== LoginErrors.NeedTwoFactor ? 'credentials' : 'two_factor'

  const errorMessage = match(error)
    .with(
      LoginErrors.Invalid,
      () => 'Login failed. Please check your email and password.',
    )
    .with(LoginErrors.Unknown, () => 'Server Error. Please try again shortly')
    .with(LoginErrors.NeedTwoFactor, () => '')
    .otherwise(() => '')

  return (
    <form
      className={cn('form', 'tw-flex', 'tw-flex-col', 'tw-w-full')}
      onSubmit={onSubmit}
    >
      {state === 'credentials' && (
        <>
          <label htmlFor="login-email" className={cn('tw-text-secondary')}>
            Email
            <TextInput
              type="email"
              name="email"
              id="login-email"
              value={email}
              onChange={evt => setEmail(evt.target.value)}
              required
            />
          </label>

          <label htmlFor="login-password">
            Password
            <TextInput
              type="password"
              name="password"
              id="login-password"
              value={password}
              onChange={evt => setPassword(evt.target.value)}
              required
            />
          </label>

          <div className={cn('tw-mb-4')}>
            <Link
              prefetch={false}
              href="/app/password"
              className={cn('tw-text-link', 'tw-mt-1')}
            >
              Forgot Password
            </Link>
          </div>
        </>
      )}

      {state === 'two_factor' && (
        <>
          <div className={cn('tw-mb-4')}>
            Check your authenticator mobile app for your authentication code
          </div>
          <label htmlFor="login-two-factor">Two-Factor Auth Code</label>
          <input
            type="number"
            name="two-factor"
            id="login-two-factor"
            value={twoFactorCode}
            onChange={evt => setTwoFactorCode(evt.target.value)}
            placeholder="042727"
            required
          />
        </>
      )}

      {errorMessage && (
        <div className={cn('tw-text-error', 'tw-text-center', 'tw-w-full')}>
          {errorMessage}
        </div>
      )}

      <div className="tw-self-center">
        <Button
          theme="primary"
          type="submit"
          disabled={success || loading || !email || !password}
        >
          {loading || success ? 'Submitting...' : 'Sign In'}
        </Button>
      </div>
      {state === 'credentials' && (
        <>
          <div
            className={cn(
              'googleLoginCta',
              'tw-flex',
              'tw-flex-col',
              'tw-items-center',
              'tw-gap-8',
              'tw-mt-8',
              'tw-self-center',
            )}
          >
            <b className={cn('tw-text-primary', 'tw-font-semibold')}>OR</b>

            <div className={cn('tw-flex', 'tw-flex-col', 'tw-gap-4')}>
              <GoogleSignIn onSignIn={onGoogleSignIn} />
            </div>
          </div>
        </>
      )}
    </form>
  )
}
