'use client'

import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  InputHTMLAttributes,
} from 'react'
import cn from 'classnames'

export interface OwnProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  value?: InputHTMLAttributes<HTMLInputElement>['value']
  label?: string
  helperText?: string
  disabled?: boolean
  error?: boolean
  placeholder?: string
  noMargin?: boolean
  type?: string
  secondary?: boolean
}

const TextInput: FC<OwnProps> = ({
  className = '',
  onChange,
  onFocus,
  onBlur,
  value,
  label,
  helperText,
  disabled,
  error,
  placeholder,
  type,
  ...inputProps
}) => {
  return (
    <input
      className={cn(
        'tw-bg-transparent',
        'tw-border',
        'tw-border-secondary',
        'tw-rounded-[10px]',
        'tw-w-full',
        'tw-py-2',
        'tw-px-3',
        'tw-text-base',
        'tw-text-primary',
        'placeholder:tw-text-placeholder',
        'focus:tw-outline-none',
        'focus:tw-border-primary',
        'focus:tw-ring-1',
        'focus:tw-ring-primary',
        className,
      )}
      type={type || 'text'}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      placeholder={placeholder}
      {...inputProps}
    />
  )
}

export default TextInput
